/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-09-23 15:18:55
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-06-29 15:48:02
 * @Description: 
 * @FilePath: /src/api/modules/user.js
 */
/**
 * 获取用户信息
 */
export const GET_USER_INFO = '/api/oauth/unionUserInfo?hideLevel=true';
/**
 * 获取订单统计数据
 */
export const GET_ORDER_STATISTICS = '/api/order/movie/self/statistics';
/**
 * 修改密码
 */
export const UPDATE_PASSWORD = '/api/account/updatePassword';
/**
 * 公众号登录
 */
export const WX_SUBSCRIPTION_LOGIN = '/api/wxMp/getWxMpLoginConfig';
/**
 * 用户绑定微信openid
 */
export const WX_SUBSCRIPTION_BIND = "/api/wxMp/getWxMpPlatformBindConfig";
