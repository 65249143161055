/**
 * 发送短信
 */
export const SMS_SEND = '/api/sms/sendVerifyCode';
/**
 * 通过验证码绑定手机号
 */
export const MOBILE_LOGIN = '/api/login/mobileVerifyCode';
/**
 * 通过验证码绑定手机号
 */
export const MOBILE_BIND_ACCOUNT = 'api/account/bindMobile';
/**
 * 生成token防止重复提交
 */
export const GENERATE_TOKEN = '/api/common/token/generate';
/**
 * 获取banner/icon
 */
export const GET_BANNER_OR_ICON = '/api/advertisement';
/**
 * 获取初始化配置
 */
export const GET_INIT_CONFIG = '/api/v1/platform';
/**
 * 获取wx初始化配置
 */
export const GET_WX_CONFIG = '/api/v1/wx/mp/createJsapiSignature';
/**
 * 获取微信分享配置
 */
export const GET_WX_SHARE_CONFIG = '/api/v1/wx/mp/share';