<template>
    <div class="components confirm">
        <div class="box">
            <div class="title">{{ title }}</div>
            <div class="message" v-html="message"></div>
            <div class="btn-line">
                <div
                    class="btn-item confirm-btn"
                    v-if="switchBtn"
                    v-html="confirmText"
                    @click="clickSuccess"
                ></div>
                <div
                    class="btn-item cancel-btn"
                    v-if="!hideCancelBtn"
                    v-html="cancelText"
                    @click="clickCancel"
                ></div>
                <div
                    class="btn-item confirm-btn"
                    v-if="!switchBtn"
                    v-html="confirmText"
                    @click="clickSuccess"
                ></div>
            </div>
        </div>
        <div class="box-bg" @click="closeConfirm"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: [
        'title',
        'message',
        'confirmText',
        'cancelText',
        'switchBtn',
        'hideCancelBtn',
        'isShieldClose',
    ],
    created() {},
    methods: {
        closeConfirm() {
            if (this.isShieldClose) {
                return;
            }
            this.$confirm.error({
                success: true,
                source: 'mode',
            });
            this.$confirm.close();
        },
        clickSuccess() {
            this.$confirm.success({
                success: true,
                source: 'btn',
            });
            this.$confirm.close();
        },
        clickCancel() {
            this.$confirm.error({
                success: true,
                source: 'btn',
            });
            this.$confirm.close();
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
.confirm {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    .box {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: .pxToRem(96) [];
        right: .pxToRem(96) [];
        transform: translateY(-50%);
        background: white;
        border-radius: .pxToRem(20) [];
        .title {
            width: 100%;
            text-align: center;
            box-sizing: border-box;
            padding-top: .pxToRem(26) [];
            padding-bottom: .pxToRem(20) [];
            color: #333333;
            font-size: .pxToRem(32) [];
            line-height: .pxToRem(45) [];
            font-weight: 500;
        }
        .message {
            box-sizing: border-box;
            padding: 0 .pxToRem(35) [];
            padding-bottom: .pxToRem(40) [];
            font-size: .pxToRem(28) [];
            line-height: .pxToRem(40) [];
            text-align: center;
            color: #333333;
        }
        .btn-line {
            height: .pxToRem(90) [];
            line-height: .pxToRem(90) [];
            display: -webkit-flex;
            -webkit-justify-content: space-around;
            display: flex;
            justify-content: space-around;
            .btn-item {
                width: 100%;
                flex: 1;
                font-size: .pxToRem(30) [];
                text-align: center;
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 1px;
                    background: #ebebee;
                }
                &:first-child {
                    &:after {
                        display: none;
                    }
                }
            }
            .cancel-btn {
                color: #8d8d8d;
            }
            .confirm-btn {
                color: #000000;
            }
            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 1px;
                background: #ebebee;
            }
        }
    }
    .box-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
    }
}
</style>
