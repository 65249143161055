<style lang="less">
    .picker {
      .picker-slot {
        width: 100%;
        .picker-item {
          &:first-child {
            overflow: unset;
            &:after {
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 200%;
              transform: translateY(-100%);
              z-index: 100;
            }
          }
          &:last-child {
            overflow: unset;
            &:after {
              content: " ";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 200%;
              transform: translateY(50%);
              z-index: 100;
            }
          }
        }
      }
    }
</style>

<style lang="less" scoped>
    @import url("../../lib/base");
    .component {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      .mint-popup {
        width: 100vw;
      }
    }
    .cancel-order-picker-line {
      position: relative;
      height: .pxToRem(80) [];
      width: 100%;
      border-bottom: 1px solid #f6f6f6;
      .confirm,
      .cancel {
        position: absolute;
        top: 0;
        line-height: .pxToRem(80) [];
        font-size: .pxToRem(32) [];
      }
      .confirm {
        right: .pxToRem(40) [];
      }
      .cancel {
        left: .pxToRem(40) [];
      }
    }
</style>

<template>
    <div class="component conponent-picker">
        <Popup position="bottom"
               v-model="popupStatus">
            <div class="cancel-order-picker-line">
                <div class="cancel"
                     @click="popupCancel">取消</div>
                <div class="confirm"
                     @click="popupComfirm">确定</div>
            </div>
            <Picker :slots="slots"
                    :valueKey="keyName"
                    @change="onChange"></Picker>
        </Popup>
    </div>
</template>
<script>
    import { Picker } from 'mint-ui';
    import { Popup } from 'mint-ui';
    export default {
        data() {
            return {
                popupStatus: true,
                pickerSlot: [{
                    values: [],
                    textAlign: 'center',
                    className: 'cancel-order-picker'
                }],
                currentItem: {},
                currentIndex: 0,
                cssText: '',
            }
        },
        components: {
            Picker,
            Popup
        },
        computed: {
            slots() {
                this.pickerSlot[0].values = this.list
                return this.pickerSlot;
            }
        },
        props: ['list', 'keyName', 'close', 'cancel', 'confirm',],
        watch: {
            popupStatus(val) {
                if (!val) {
                    if (this.close) {
                        this.close();
                    }
                    else {
                        this.$util.unlockScreen();
                        this.$picker.close();
                    }
                }
            }
        },
        created() {
            this.popupStatus = true;
            this.$util.lockScreen();
        },
        methods: {
            popupCancel() {
                if (this.cancel) {
                    this.cancel();
                }
                else {
                    this.popupStatus = false;
                }
            },
            popupComfirm() {
                document.body.cssText = this.cssText;
                if (this.confirm) {
                    this.$util.unlockScreen();
                    this.confirm(this.currentItem, this.currentIndex);
                }
                else {
                    this.$picker.close();
                }
            },
            onChange(picker, values) {
                for (let i = 0; i < this.list.length; i++) {
                    const item = this.list[i];
                    if (item == values[0]) {
                        this.currentIndex = i;
                    }
                }
                this.currentItem = values[0];
            }
        }
    }
</script>

