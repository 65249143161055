import iframe from './index.vue';
import Vue from 'vue';

let el = null;

const _iframe = {
    // iframe 打开
    open({url = ''}) {
        if (el) {
            return;
        }
        if( url == ''){
            return;
        }
        let model = Vue.extend(iframe);
        let csVm = new model()
        csVm.url = url;
        el = csVm.$mount().$el;
        // 插入dom
        document.body.appendChild(el);
    },
    close() {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
    }
}

export default _iframe;