/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-03-03 18:52:31
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-08-20 14:32:18
 * @Description: 
 * @FilePath: /src/api/modules/location.js
 */
/**
 * 经纬度换取城市信息
 */
export const LOCATION_TO_CITY = '/api/city/location';
/**
 * 获取地区下级区数据
 */
export const GET_CITY_REGION_LIST = '/api/city/regionList';
/**
 * 获取热门城市
 */
export const GET_HOST_CITY_LIST = '/api/city/hotList';
/**
 * 获取所有城市列表
 */
export const GET_CITY_LIST = '/api/v2/cities/groupByFirstLetter';
/**
 * 城市编码获取城市id
 */
export const GET_CITY_ID = '/api/v2/cities/getCityByCityCode';
