<template>
    <div class="iframe">
        <iframe :src="url"
                frameborder="0"></iframe>
    </div>

</template>
<script>
    export default {
        data() {
            return {

            }
        },
        props: ['url']
    }
</script>
<style lang="less" scoped>
    .iframe {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 0;
      height: 0;
      visibility: hidden;
    }
</style>

