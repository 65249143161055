/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-07-13 11:29:40
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-22 17:41:01
 * @Description: 
 * @FilePath: /src/store/modules/location.js
 */
import { LOCATION_CURRENT, LOCATION_SET } from 'constant';
import util from '@/lib/util';
const location = {
    state: {
        isShowFirst: false,
        isHasLocation: true,
        currentLocation: {
            city: {},
        },
        selectLocation: {
            city: {},
        },
    },
    mutations: {
        setCurrentLocation(state, data) {
            if (!data) {
                return;
            }
            state.currentLocation = data;
            state.isHasLocation = true;
            localStorage.setItem(LOCATION_CURRENT, JSON.stringify(data));
        },
        setSelectLocation(state, data) {
            if (!data) {
                return;
            }
            state.selectLocation = data;
            localStorage.setItem(LOCATION_SET, JSON.stringify(data) || { city: {} });
        },
        setShowFirst(state, data) {
            state.isShowFirst = data;
        },
        setHasLocation(state, data) {
            state.isHasLocation = data;
        },
    },
    getters: {
        getCurrentLocation: state => {
            if (state.selectLocation.city.id) {
                return state.selectLocation;
            } else if (state.currentLocation.city.id) {
                return state.currentLocation;
            } else {
                util.getLocation();
                return {
                    city: {},
                };
            }
        },
    },
};

export default location;
