/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-11-30 19:32:17
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2020-12-10 16:48:54
 * @Description:
 * @FilePath: /src/components/payType/index.js
 */
import paySelect from './index.vue';
import Vue from 'vue';

let el = null;
let successFun = null;
const _paySelect = {
    // paySelect 打开
    open({ payType = null, success } = {}) {
        if (el) {
            return;
        }
        let model = Vue.extend(paySelect);
        successFun = success;
        let paySelectVm = new model();
        // 当存在 payType 时 直接跳过选择
        if (payType) {
            for (let i = 0; i < paySelectVm.payList.length; i++) {
                const item = paySelectVm.payList[i];
                if (item.type == payType) {
                    paySelectVm.selectIndex = i;
                    paySelectVm.submit();
                    // 匹配成功 不插入dom 
                    return;
                }
            }
        }
        el = paySelectVm.$mount().$el;
        document.body.appendChild(el);
    },
    close(_this = null) {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
    },
    callback(res) {
        if (res.success) {
            if (successFun) {
                successFun(res);
            }
        }
    },
};

export default _paySelect;
