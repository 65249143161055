/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-04-28 10:02:03
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-06-16 10:23:57
 * @Description:
 * @FilePath: /src/store/modules/user.js
 */
import {
    QZ_TOKEN,
    QZ_PLATFORM_USER_OPEN_ID,
    QZ_PLATFORM_APP_ID,
    QZ_WX_OPEN_ID,
} from '@/lib/constant';

const user = {
    state: {
        token: null,
        userInfo: {},
        roles: null,
        platformUserOpenId: '',
        platformAppId: '',
        wxOpenId: '',
    },
    mutations: {
        setToken(state, data) {
            localStorage.setItem(QZ_TOKEN, data);
            state.token = data;
            this.commit('reloadApp');
            // socket.start();
        },
        clearToken(state) {
            localStorage.removeItem(QZ_TOKEN);
            state.token = null;
        },
        setUserInfo(state, data) {
            state.userInfo = data;
        },
        setRoles(state, data) {
            state.roles = data;
        },
        setPlatformUserOpenId(state, data) {
            localStorage.setItem(QZ_PLATFORM_USER_OPEN_ID, data);
            state.platformUserOpenId = data;
        },
        setPlatformAppId(state, data) {
            localStorage.setItem(QZ_PLATFORM_APP_ID, data);
            state.platformAppId = data;
        },
        setWxOpenId(state, data) {
            localStorage.setItem(QZ_WX_OPEN_ID, data);
            state.wxOpenId = data;
        },
    },
};

export default user;
