import {
    QZ_PLATFORM_ID,
    QZ_SUB_PLATFORM_ID,
    QZ_CURRENT_SCENE,
    QZ_SCENE_TYPE,
    QZ_CURRENT_SCENE_VALUE,
    QZ_MERCH_ID,
    QZ_PROMOTER_ID,
} from 'constant';
import util from 'util';
import { GET_INIT_CONFIG } from 'api';
const common = {
    state: {
        // 初始化状态
        initialization: false,
        // 微信config注册时域名
        wxConfigHref: '',
        env: '',
        config: {
            // TODO
            // enableFastDrawMode: true,
            enableFastDrawMode: false,
            kfcTakeoutDeliveryFee: 9,
        },
        title: {
            status: false,
            systemHeight: '0px',
            titleHeight: '0px',
            titleName: '',
        },
        isReloadApp: false,
        platformId: null,
        subPlatformId: null,
        merchId: '',
        promoterId: '',
        allowEatType: false,
        // 当前场景值类型 0. 默认运行查询结果 1. 设置获得
        sceneType: 0,
        currentScene: 0,
        message: null,
        bodyCssText: '',
        // 微信支付的配置信息
        wechatPay: {
            status: false,
            allowDomains: [],
            redirectDomain: '',
        },
    },
    mutations: {
        setInitialization(state, data) {
            state.initialization = data;
        },
        setWxConfigHref(state, data) {
            if (state.wxConfigHref == '') {
                state.wxConfigHref = data;
            }
        },
        setEnv(state, data) {
            state.env = data;
        },
        setConfig(state, data) {
            state.config = data;
        },
        reloadApp(state) {
            state.isReloadApp = true;
        },
        startApp(state) {
            state.isReloadApp = false;
        },
        setPlatformId(state, data) {
            state.platformId = data;
            localStorage.setItem(QZ_PLATFORM_ID, data);
        },
        setSubPlatformId(state, data) {
            state.subPlatformId = data;
            localStorage.setItem(QZ_SUB_PLATFORM_ID, data);
        },
        setAllowEatType(state, data) {
            state.allowEatType = data;
        },
        setSceneType(state, data) {
            state.sceneType = data;
            window.localStorage.setItem(QZ_SCENE_TYPE, data);
        },
        setCurrentScene(state, data) {
            if (state.currentScene == QZ_CURRENT_SCENE.APP_MG && data == QZ_CURRENT_SCENE.APP) {
                data = QZ_CURRENT_SCENE.APP_MG;
            }
            state.currentScene = data;
            window.localStorage.setItem(QZ_CURRENT_SCENE_VALUE, data);
        },
        setTitleStatus(state, data) {
            state.title.status = data;
        },
        setSystemHeight(state, data) {
            state.title.systemHeight = data;
        },
        setTitleHeight(state, data) {
            state.title.titleHeight = data;
        },
        setTitleName(state, data) {
            state.title.titleName = data;
        },
        clearTitleName(state) {
            state.title.titleName = '';
        },
        setMessage(state, data) {
            state.message = data;
        },
        clearMessage(state) {
            state.message = null;
        },
        setBodyCssText(state, data) {
            state.bodyCssText = data;
        },
        setWechatPayStatus(state, data) {
            state.wechatPay.status = data;
        },
        setWechatPayAllowDomains(state, data) {
            state.wechatPay.allowDomains = data;
        },
        setWechatPayRedirectDomain(state, data) {
            state.wechatPay.redirectDomain = data;
        },
        setMerchId(state, data) {
            state.merchId = data;
            localStorage.setItem(QZ_MERCH_ID, data);
        },
        setPromoterId(state, data) {
            state.promoterId = data;
            localStorage.setItem(QZ_PROMOTER_ID, data);
        },
    },
    getters: {
        getExposeMethod: state => {
            return getExposeMethodNext(state, state.currentScene);
        },
        initialization: state => {
            return state.initialization;
        },
        isApp: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.APP, state.currentScene);
        },
        isWechatMin: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.WX_MIN, state.currentScene);
        },
        isWechatBrowser: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.WX_BROWSER, state.currentScene);
        },
        isTTMin: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.TT_MIN, state.currentScene);
        },
        isAlipayBrowser: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.ALIPAY_BROWSER, state.currentScene);
        },
        isAlipayMin: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.ALIPAY_MIN, state.currentScene);
        },
        isBDMin: state => {
            return JudgeTheEnvironment(QZ_CURRENT_SCENE.BD_MIN, state.currentScene);
        },
    },
    actions: {
        wechatPay: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                if (state.wechatPay.status) {
                    resolve(state.wechatPay);
                } else {
                    util.get(`${GET_INIT_CONFIG}/${state.platformId}`).then(res => {
                        if (res.success) {
                            commit('setWechatPayStatus', true);
                            commit('setWechatPayAllowDomains', res.data.wechatPayAllowDomains);
                            commit('setWechatPayRedirectDomain', res.data.wechatPayRedirectDomain);
                        }
                        resolve(state.wechatPay);
                    });
                }
            });
        },
        getExposeMethod: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                if (state.currentScene == 0) {
                    util.setCurrentScene().then(res => {
                        if (res.success) {
                            resolve(getExposeMethodNext(state, res.currentScene));
                        }
                    });
                } else {
                    resolve(getExposeMethodNext(state, state.currentScene));
                }
            });
        },
    },
};

function getExposeMethodNext(state, currentScene) {
    let configName = '';
    if (JudgeTheEnvironment(QZ_CURRENT_SCENE.APP, currentScene)) {
        configName = 'app';
    } else if (JudgeTheEnvironment(QZ_CURRENT_SCENE.WX_MIN, currentScene)) {
        configName = 'wechat_miniapp';
    } else if (JudgeTheEnvironment(QZ_CURRENT_SCENE.WX_BROWSER, currentScene)) {
        configName = 'wechat_h5';
    } else if (JudgeTheEnvironment(QZ_CURRENT_SCENE.BD_MIN, currentScene)) {
        configName = 'wechat_miniapp';
    } else if (JudgeTheEnvironment(QZ_CURRENT_SCENE.ALIPAY_MIN, currentScene)) {
        configName = 'wechat_miniapp';
    }
    if (configName == '') {
        return {};
    }
    if (state.config && state.config[configName] && state.config[configName].exposeMethodName) {
        return JSON.parse(state.config[configName].exposeMethodName);
    } else {
        return {};
    }
}

function JudgeTheEnvironment(standardScene, currentScene) {
    if (currentScene >= standardScene && currentScene < standardScene + QZ_CURRENT_SCENE.STEP) {
        return true;
    }
    return false;
}

export default common;
