import customerService from './index.vue';
import Vue from 'vue';

let el = null;

const _customerService = {
    // customerService 打开
    open() {
        if (el) {
            return;
        }
        let model = Vue.extend(customerService);
        let csVm = new model()
        el = csVm.$mount().$el;
        // 插入dom
        document.body.appendChild(el);
    },
    close() {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
    }
}

export default _customerService;