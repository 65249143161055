/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-09-23 15:18:55
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-17 16:33:05
 * @Description: 
 * @FilePath: /src/store/index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import user from './modules/user';
import location from './modules/location';
import kfc from './modules/kfc';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    modules: {
        common,
        user,
        location,
        kfc,
    },
    strict: process.env.VUE_APP_MY_NODE !== 'PRO',
});
