import Vue from 'vue';
import VueRouter from 'vue-router';
import {
    PAGE_ROOT,
    PAGE_RESTAURANT_SELECT,
    PAGE_SET_CITY,
    PAGE_RESTAURANT_LIST,
    PAGE_STOP_MENU,
    PAGE_PRODUCT_DETAIL,
    PAGE_ORDER_CREATE,
    PAGE_ORDER_CREATE_BY_AGISO,
    PAGE_ORDER_INFO,
    PAGE_ORDER_LIST,
    PAGE_ORDER_PAY,
    PAGE_CUSTOMER_SERVICE,
    PAGE_OLD_INDEX,
    PAGE_OLD_ORDER_LIST,
    PAGE_ADDRESS_LIST,
    PAGE_ADDRESS_DETAIL,
    PAGE_ADDRESS_SEARCH,
    PAGE_EAT_TYPE,
    PAGE_NOT_TAB_EAT_TYPE,
    PAGE_WECHAT_PAY,
    PAGE_WX_SHARE,
    PAGE_KFC_CARD,
    PAGE_KFC_RESTAURANT_CARD,
    PAGE_WALLET,
    PAGE_EXPRESS,
} from 'page';
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
//     return originalPush.call(this, location).catch(err => err);
// };

Vue.use(VueRouter);

const routes = [
    {
        // 首页
        path: PAGE_ROOT,
        name: 'Root',
        meta: {
            zhCnName: '肯德基',
            keepAlive: true,
        },
        redirect: PAGE_EAT_TYPE,
    },
    {
        path: PAGE_RESTAURANT_SELECT,
        name: 'Index',
        component: resolve => require(['@/views/kfc/restaurant/select.vue'], resolve),
        meta: {
            zhCnName: '肯德基',
            zhCnHeadTitle: '选择餐厅',
            keepAlive: false,
        },
    },
    // 旧的首页
    {
        path: PAGE_OLD_INDEX,
        name: 'OldIndex',
        meta: {
            zhCnName: '肯德基',
            keepAlive: true,
        },
        component: resolve => require(['@/views/old/index.vue'], resolve),
    },
    // 餐厅模块
    {
        path: PAGE_RESTAURANT_LIST,
        name: 'RestaurantList',
        meta: {
            zhCnName: '切换餐厅',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/restaurant/list.vue'], resolve),
    },
    {
        path: PAGE_STOP_MENU,
        name: 'RestaurantMenu',
        meta: {
            zhCnName: '菜单',
            zhCnHeadTitle: '菜单',
            keepAlive: true,
        },
        component: resolve => require(['@/views/kfc/restaurant/menu.vue'], resolve),
    },
    {
        path: PAGE_PRODUCT_DETAIL,
        name: 'RestaurantDetail',
        meta: {
            zhCnName: '商品详情',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/restaurant/detail.vue'], resolve),
    },
    // 收获地址模块
    {
        path: PAGE_ADDRESS_LIST,
        name: 'AddressList',
        meta: {
            zhCnName: '选择收货地址',
            keepAlive: false,
        },
        component: resolve => require(['@/views/address/list.vue'], resolve),
    },
    {
        path: PAGE_ADDRESS_DETAIL,
        name: 'AddressDetail',
        meta: {
            zhCnName: '编辑地址',
            keepAlive: true,
        },
        component: resolve => require(['@/views/address/detail.vue'], resolve),
    },
    {
        path: PAGE_ADDRESS_SEARCH,
        name: 'AddressSearch',
        meta: {
            zhCnName: '搜索地址',
            keepAlive: false,
        },
        component: resolve => require(['@/views/address/search.vue'], resolve),
    },
    // 订单模块
    {
        path: PAGE_ORDER_CREATE,
        name: 'KFCOrderCreate',
        meta: {
            zhCnName: '确认订单',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/create.vue'], resolve),
    },
    {
        path: PAGE_ORDER_CREATE_BY_AGISO,
        name: 'KFCOrderCreate4Agiso',
        meta: {
            zhCnName: '确认订单',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/create4agiso.vue'], resolve),
    },
    // {
    //     path: PAGE_ORDER_LIST,
    //     name: 'KFCOrderList',
    //     component: resolve => require(['@/views/main.vue'], resolve),
    //     meta: {
    //         zhCnName: '订餐记录',
    //         zhCnHeadTitle: '订单列表',
    //         keepAlive: false,
    //     },
    //     children: [
    //         {
    //             // tabbar order list
    //             path: PAGE_ORDER_LIST,
    //             name: 'KFCOrderList',
    //             meta: {
    //                 zhCnName: '订餐记录',
    //                 zhCnHeadTitle: '订单列表',
    //                 keepAlive: false,
    //             },
    //             component: resolve => require(['@/views/kfc/order/list.vue'], resolve),
    //         },
    //     ],
    // },
    {
        path: PAGE_ORDER_LIST,
        redirect: to => {
            return {
                path: PAGE_OLD_ORDER_LIST,
                query: to.query,
            };
        },
    },
    {
        path: PAGE_OLD_ORDER_LIST,
        name: 'KFCOldOrderList',
        meta: {
            zhCnName: '订餐记录',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/list.vue'], resolve),
    },
    {
        path: PAGE_ORDER_INFO,
        name: 'KFCOrderInfo',
        meta: {
            zhCnName: '订单详情',
            zhCnHeadTitle: '订单详情',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/info.vue'], resolve),
    },
    {
        path: PAGE_ORDER_PAY,
        name: 'KFCOrderPay',
        meta: {
            zhCnName: '订单详情',
            zhCnHeadTitle: '订单详情',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/pay.vue'], resolve),
    },
    // 定位模块
    {
        path: PAGE_SET_CITY,
        name: 'SetCity',
        meta: {
            zhCnName: '选择城市',
            keepAlive: true,
        },
        component: resolve => require(['@/views/location/city.vue'], resolve),
    },
    // 客服模块
    {
        path: PAGE_CUSTOMER_SERVICE,
        name: 'CustomerService',
        meta: {
            zhCnName: '在线客服',
            zhCnHeadTitle: '在线客服',
            keepAlive: false,
        },
        component: resolve => require(['@/views/customer-service/index.vue'], resolve),
    },
    // 切换就餐方式
    // {
    //     path: PAGE_EAT_TYPE,
    //     name: 'EatType',
    //     component: resolve => require(['@/views/main.vue'], resolve),
    //     meta: {
    //         zhCnName: '选择就餐方式',
    //         keepAlive: false,
    //     },
    //     children: [
    //         {
    //             path: PAGE_EAT_TYPE,
    //             name: 'EatType',
    //             meta: {
    //                 zhCnName: '肯德基',
    //                 zhCnHeadTitle: '肯德基专享特惠',
    //                 keepAlive: false,
    //             },
    //             component: resolve =>
    //                 require(['@/views/kfc/restaurant/switchEatType.vue'], resolve),
    //         },
    //     ],
    // },
    {
        path: PAGE_EAT_TYPE,
        redirect: to => {
            return {
                path: PAGE_NOT_TAB_EAT_TYPE,
                query: Object.assign(to.query),
            };
        },
    },
    {
        path: PAGE_NOT_TAB_EAT_TYPE,
        name: 'NotTabEatType',
        component: resolve => require(['@/views/kfc/restaurant/switchEatType.vue'], resolve),
        meta: {
            zhCnName: '选择就餐方式',
            keepAlive: true,
        },
    },
    {
        path: PAGE_WECHAT_PAY,
        name: 'CommonWechatPay',
        meta: {
            zhCnName: '',
            keepAlive: false,
        },
        component: resolve => require(['@/views/pay/wechat.vue'], resolve),
    },
    {
        path: PAGE_WX_SHARE,
        name: 'WxShare',
        meta: {
            zhCnName: '',
            keepAlive: false,
        },
        component: resolve => require(['@/views/share/wechat.vue'], resolve),
    },
    // 卡包
    // {
    //     path: PAGE_KFC_CARD,
    //     name: 'KfcCard',
    //     component: resolve => require(['@/views/main.vue'], resolve),
    //     meta: {
    //         zhCnName: '我的卡包',
    //         keepAlive: false,
    //     },
    //     children: [
    //         {
    //             path: PAGE_KFC_CARD,
    //             name: 'KfcCard',
    //             meta: {
    //                 zhCnName: '肯德基',
    //                 zhCnHeadTitle: '肯德基专享特惠',
    //                 keepAlive: false,
    //             },
    //             component: resolve => require(['@/views/kfc/card/index.vue'], resolve),
    //         },
    //     ],
    // },
    {
        path: PAGE_KFC_CARD,
        redirect: to => {
            return {
                path: PAGE_KFC_RESTAURANT_CARD,
                query: Object.assign(to.query),
            };
        },
    },
    {
        path: PAGE_KFC_RESTAURANT_CARD,
        name: 'RestaurantKfcCard',
        meta: {
            zhCnName: '我的卡包',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/card/index.vue'], resolve),
    },
    {
        path: '/kfc/pay-xiaoBuDian',
        name: 'PayXiaoBuDian',
        meta: {
            zhCnName: '收银台',
            zhCnHeadTitle: '收银台',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/order/pay-xiaoBuDian.vue'], resolve),
    },
    {
        path: PAGE_WALLET,
        name: 'WALLET',
        meta: {
            zhCnName: '钱包',
            zhCnHeadTitle: '钱包',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/restaurant/wallet.vue'], resolve),
    },
    {
        path: PAGE_EXPRESS,
        name: 'EXPRESS',
        meta: {
            zhCnName: '快递',
            zhCnHeadTitle: '快递',
            keepAlive: false,
        },
        component: resolve => require(['@/views/kfc/restaurant/express.vue'], resolve),
    },
    // TODO
    {
        path: '/index',
        redirect: PAGE_ROOT,
    },
    // 默认路由
    // {
    //     path: '*',
    //     meta: {
    //         zhCnName: '肯德基',
    //         keepAlive: true,
    //     },
    //     redirect: PAGE_ROOT
    // },
];

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE,
    routes,
});

export default router;
