/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-09-23 15:18:55
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-04-14 14:46:52
 * @Description: 路由跳转关闭
 * @FilePath: /src/permission.js
 */
import store from '@/store';
import router from '@/router';
import customerService from '@/components/customerService/index.js';
import loginModel from '@/components/loginModel/index.js';
import picker from '@/components/picker/index.js';
import payType from '@/components/payType';
import util from 'util';
import {
    QZ_TOKEN,
    QZ_PLATFORM_ID,
    QZ_SUB_PLATFORM_ID,
    QZ_SCENE_TYPE,
    QZ_CURRENT_SCENE_VALUE,
    QZ_PLATFORM_USER_OPEN_ID,
    QZ_PLATFORM_APP_ID,
    QZ_WX_OPEN_ID,
    QZ_MERCH_ID,
    QZ_PROMOTER_ID,
    QZ_HEADER_APP_ID,
} from 'constant';
import {
    PAGE_ROOT,
    PAGE_ORDER_PAY,
    PAGE_ORDER_INFO,
    PAGE_WECHAT_PAY,
    PAGE_CUSTOMER_SERVICE,
    PAGE_WX_SHARE,
} from 'page';
import { GET_WX_CONFIG } from 'api';

router.beforeEach((to, from, next) => {
    util.clearMyTime();
    store.commit('setWxConfigHref', window.location.href);
    if (!store.getters.initialization) {
        const query = router.history.pending.query;
        store.commit('setInitialization', true);
        let promiseList = [];
        /**
         * 1. 处理需要隐藏的数据，然后刷新页面。 - Start
         */
        // 存储token
        var isReload = false;
        if (query.token) {
            isReload = true;
            localStorage.setItem(QZ_TOKEN, query.token);
            delete query.token;
        }
        // 存储wxOpenId
        if (query.wxOpenId) {
            isReload = true;
            localStorage.setItem(QZ_WX_OPEN_ID, query.wxOpenId);
            delete query.wxOpenId;
        }
        // 存储 AppId
        if (query.headerAppId) {
            isReload = true;
            localStorage.setItem(QZ_HEADER_APP_ID, query.headerAppId);
            delete query.headerAppId;
        }
        // 刷新页面
        if (isReload) {
            let url = `${window.location.pathname}?`;
            for (let k in query) {
                if (k == 'returnUrl' || k == 'orderInfoUrl') {
                    url += `${k}=${encodeURIComponent(query[k])}&`;
                } else {
                    url += `${k}=${query[k]}&`;
                }
            }
            router.replace(url);
            // window.location.replace(url.substring(0, url.length - 1));
        }
        /**
         * 1. - End
         */

        /**
         * 2. 处理需要请求的数据 - Start
         */
        // 渠道id
        if (query.platformId) {
            if (query.platformId.indexOf(',') > -1) {
                query.platformId = query.platformId.split(',');
            }
            if (typeof query.platformId == 'object') {
                query.platformId = query.platformId[query.platformId.length - 1];
            }
            if (query.platformId) {
                promiseList.push({
                    name: 'platformId',
                    request: () => {
                        util.getPlatformInfo(query.platformId);
                    },
                });
                store.commit('setPlatformId', query.platformId);
            }
        } else if (localStorage.getItem(QZ_PLATFORM_ID)) {
            promiseList.push({
                name: 'platformId',
                request: util.getPlatformInfo,
            });
            store.commit('setPlatformId', localStorage.getItem(QZ_PLATFORM_ID));
        }
        // 设置token
        if (localStorage.getItem(QZ_TOKEN)) {
            promiseList.push({
                name: 'token',
                request: util.getUserInfo,
            });
            store.commit('setToken', localStorage.getItem(QZ_TOKEN));
        }
        // 设置环境场景值
        if (query.currentScene) {
            store.commit('setSceneType', 1);
            store.commit('setCurrentScene', query.currentScene);
        } else {
            var sceneType = window.localStorage.getItem(QZ_SCENE_TYPE) || 0;
            var currentScene = window.localStorage.getItem(QZ_CURRENT_SCENE_VALUE);
            if (sceneType == 1 && currentScene) {
                store.commit('setSceneType', 1);
                store.commit('setCurrentScene', currentScene);
            } else {
                promiseList.push({
                    name: 'currentScene',
                    request: util.setCurrentScene,
                });
            }
        }
        // 获取定位信息
        if (
            window.location.pathname != PAGE_ORDER_PAY &&
            window.location.pathname != PAGE_ORDER_INFO &&
            window.location.pathname != PAGE_WECHAT_PAY &&
            window.location.pathname != PAGE_CUSTOMER_SERVICE &&
            window.location.pathname != '/kfc/pay-xiaoBuDian'
        ) {
            let getLocationData = {};
            if (query.lat && query.lng) {
                getLocationData.presetLocation = {
                    lat: query.lat,
                    lng: query.lng,
                };
                promiseList.push({
                    name: 'getLocation',
                    request: () => {
                        util.getLocation(getLocationData);
                    },
                });
            } else {
                util.getLocation(getLocationData);
            }
        }
        Promise.all(
            promiseList.map(v => {
                return v.request();
            })
        ).then(([...promiseResList]) => {
            for (let i = 0; i < promiseList.length; i++) {
                const promiseItem = promiseList[i];
                const promiseResItem = promiseResList[i];
                console.log('permission', promiseItem.name, promiseResItem);
                switch (promiseItem.name) {
                    case 'currentScene':
                        store.dispatch('wechatPay').then(wechatPayRes => {
                            const writeDomains = wechatPayRes.allowDomains;
                            if (store.getters.isWechatBrowser) {
                                wxShare();
                            }
                        });
                        break;

                    default:
                        break;
                }
            }
            next();
        });
        /**
         * 2. - End
         */

        /**
         * 3. 同步数据 - Start
         */
        // 子渠道id
        if (query.subPlatformId) {
            store.commit('setSubPlatformId', query.subPlatformId);
        } else if (localStorage.getItem(QZ_SUB_PLATFORM_ID)) {
            store.commit('setSubPlatformId', localStorage.getItem(QZ_SUB_PLATFORM_ID));
        }
        // merchId promoterId
        if (query.merchId) {
            store.commit('setMerchId', query.merchId);
        } else if (localStorage.getItem(QZ_MERCH_ID)) {
            store.commit('setMerchId', localStorage.getItem(QZ_MERCH_ID));
        }
        // promoterId
        if (query.promoterId) {
            store.commit('setPromoterId', query.promoterId);
        } else if (localStorage.getItem(QZ_PROMOTER_ID)) {
            store.commit('setPromoterId', localStorage.getItem(QZ_PROMOTER_ID));
        }
        // 同步标题
        if (query.titleName) {
            util.setTitleName(decodeURIComponent(query.titleName));
        }
        // 同步第三方openId
        if (query.openId) {
            store.commit('setPlatformUserOpenId', query.openId);
        } else if (localStorage.getItem(QZ_PLATFORM_USER_OPEN_ID)) {
            store.commit('setPlatformUserOpenId', localStorage.getItem(QZ_PLATFORM_USER_OPEN_ID));
        }
        // 同步第三方appId
        if (query.appId) {
            store.commit('setPlatformAppId', query.appId);
        } else if (localStorage.getItem(QZ_PLATFORM_APP_ID)) {
            store.commit('setPlatformAppId', localStorage.getItem(QZ_PLATFORM_APP_ID));
        }
        // 设置wxOpenId
        if (localStorage.getItem(QZ_WX_OPEN_ID)) {
            store.commit('setWxOpenId', localStorage.getItem(QZ_WX_OPEN_ID));
        }
        /**
         * 3. - End
         */
    } else {
        next();
    }
    util.unlockScreen({ _top: 0 });
    customerService.close();
    loginModel.close();
    picker.close();
    payType.close();
    // next();
});

function wxShare() {
    // 初始化wx.config
    util.post(GET_WX_CONFIG, {
        url: store.state.common.wxConfigHref,
    }).then(res => {
        if (res.success) {
            let config = Object.assign(res.data, {
                debug: false,
                jsApiList: [
                    'chooseWXPay',
                    'getLocation',
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage',
                ],
            });
            wx.config(config);
            wx.ready(function() {
                console.log('permission', 'wxShare', '注册成功');
                const shareData = {
                    title: '肯德基点餐5折起',
                    desc: '天天有秒杀，单单享低价～',
                    link: `${window.location.origin}${PAGE_WX_SHARE}?platformId=${store.state.common.platformId}`,
                    imgUrl: 'https://ossimg.qianzhu8.com/merch/app/icon-home-kfc_v2.png',
                };

                wx.onMenuShareAppMessage(shareData);
                wx.updateAppMessageShareData(shareData);
                wx.onMenuShareTimeline(shareData);
                wx.updateTimelineShareData(shareData);
            });
            wx.error(function(res) {
                console.error('permission', 'wxShare', '注册失败', res);
            });
        }
    });
}
