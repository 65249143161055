<template>
    <div id="app"
         :class="{'show-original-price': $store.getters.getExposeMethod.showOriginalPrice}">
        <div class="current-scene"
             v-if="$store.state.common.env != 'PRO'">
            当前环境: {{$store.state.common.env}}-{{$store.state.common.currentScene}}
        </div>
        <keep-alive>
            <router-view v-if="reloadStatus && $route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="reloadStatus && !$route.meta.keepAlive" />
    </div>
</template>
<script>
    import Title from '@/components/title/index.vue';
    import { PAGE_ROOT } from 'page';
    export default {
        data() {
            return {
                reloadStatus: true,
            }
        },
        components: {
            Title
        },
        created() {
            if (this.isReloadApp) {
                this.$store.commit('startApp');
            }
            window.onload = () => {
                this.$store.commit('setBodyCssText', document.body.style.cssText)
            }
        },
        computed: {
            isReloadApp() {
                return this.$store.state.common.isReloadApp
            }
        },
        watch: {
            isReloadApp(val) {
                if (val) {
                    this.reloadStatus = false;
                    this.$nextTick(() => {
                        this.$store.commit('startApp');
                        this.reloadStatus = true;
                    })
                }
            },
            '$route'(val) {
                let defaultName = "肯德基在线点餐"
                if (this.$store.getters.getExposeMethod.expandName == "QiShiKa") {
                    defaultName = "肯德基外卖"
                }
                if (!this.$store.state.common.title.status) {
                    document.title = val.meta.zhCnHeadTitle || defaultName
                }
                else {
                    document.title = defaultName
                }
            },
        },
    }
</script>

<style lang="less">
    @import url('./lib/base.less');
    * {
        position: relative;
        padding: 0;
        margin: 0;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        //   -webkit-user-modify: read-write-plaintext-only;
        &:focus {
            outline: 0;
        }
    }
    div {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    body,
    html {
        width: 100%;
        height: 100%;
        background: #f0f0f0;
    }
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #f0f0f0;
    }
    .page {
        position: relative;
        min-height: 100vh;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .textover {
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
    .textline {
        text-decoration: line-through;
    }
    .img {
        line-height: 0;
    }
    .weui-tabbar {
        z-index: 0 !important;
    }
    input {
        border: none;
        outline: none;
    }

    // mint-ui 样式修改
    .mint-msgbox {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        backface-visibility: visible;
        -webkit-backface-visibility: visible;
        .mint-msgbox-content {
            padding: .pxToRem(40) [] .pxToRem(32) [];
            .mint-msgbox-message {
                font-size: .pxToRem(28) [];
                line-height: .pxToRem(36) [];
                color: #333333;
            }
        }
        .mint-msgbox-btns {
            .mint-msgbox-btn {
                font-size: .pxToRem(32) [];
                &.mint-msgbox-confirm {
                    color: #3cc51f;
                }
                &.mint-msgbox-cancel {
                    color: #999999;
                }
            }
        }
    }
    .mint-indicator-wrapper {
        z-index: 100;
    }
    // swiper

    .swiper-pagination-bullet-active {
        background: white !important;
    }
    // iPhone 11 Pro
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        .iphone-x-adaptation {
            padding-bottom: @tabbarSecurityHeight;
        }
        .iphone-x-adaptation-margin-bottom {
            margin-bottom: @tabbarSecurityHeight;
        }
    }
    // iPhone 11
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        .iphone-x-adaptation {
            padding-bottom: @tabbarSecurityHeight;
        }
        .iphone-x-adaptation-margin-bottom {
            margin-bottom: @tabbarSecurityHeight;
        }
    }
    // iPhone 11 Pro Max
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        .iphone-x-adaptation {
            padding-bottom: @tabbarSecurityHeight;
        }
        .iphone-x-adaptation-margin-bottom {
            margin-bottom: @tabbarSecurityHeight;
        }
    }
    // iPhone 12 and iPhone 12 Pro
    @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        .iphone-x-adaptation {
            padding-bottom: @tabbarSecurityHeight;
        }
        .iphone-x-adaptation-margin-bottom {
            margin-bottom: @tabbarSecurityHeight;
        }
    }
    // iPhone 12 Pro Max
    @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
        .iphone-x-adaptation {
            padding-bottom: @tabbarSecurityHeight;
        }
        .iphone-x-adaptation-margin-bottom {
            margin-bottom: @tabbarSecurityHeight;
        }
    }
</style>
<style lang="less" scoped>
    .current-scene {
        position: fixed;
        top: 10px;
        right: 20px;
        background: rgba(0, 0, 0, 0.2);
        padding: 5px;
        z-index: 9999;
        pointer-events: none;
        color: white;
        font-size: 12px;
        border-radius: 3px;
    }
</style>