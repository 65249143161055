<style lang="less" scoped>
    @import url('~@/lib/base');
    .mobile {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1000;
        .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
        .content {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .content-bind {
                .base-center;
                width: .pxToRem(660) [];
                background: rgba(255, 255, 255, 1);
                border-radius: .pxToRem(40) [];
                padding: .pxToRem(40) [] .pxToRem(50) [];
                box-sizing: border-box;
                .close {
                    position: absolute;
                    top: .pxToRem(44) [];
                    right: .pxToRem(30) [];
                    .sq(32);
                }
                .title {
                    text-align: center;
                    font-size: .pxToRem(34) [];
                    font-weight: 600;
                    color: #000000;
                    line-height: .pxToRem(36) [];
                }
                .line {
                    height: .pxToRem(160) [];
                    font-size: .pxToRem(24) [];
                    border-bottom: .pxToRem(1) [] solid rgba(0, 0, 0, 0.15);
                    padding-top: .pxToRem(40) [];
                    .line-title {
                        padding-top: .pxToRem(30) [];
                        height: .pxToRem(40) [];
                        line-height: .pxToRem(40) [];
                        font-size: .pxToRem(28) [];
                        color: rgba(0, 0, 0, 0.9);
                    }
                    .input {
                        font-size: .pxToRem(40) [];
                        height: .pxToRem(90) [];
                        line-height: .pxToRem(90) [];
                        display: block;
                        &::placeholder {
                            color: rgba(0, 0, 0, 0.3);
                        }
                    }
                    &.code {
                        padding-top: 0;
                    }
                }
                .flex {
                    display: flex;
                    align-items: center;
                    position: relative;
                    &.code {
                        height: .pxToRem(90) [];
                        .input {
                            width: .pxToRem(350) [];
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            display: block;
                            background-color: rgba(0, 0, 0, 0);
                        }
                    }
                }
                .yzm {
                    position: absolute;
                    right: 0;
                    bottom: .pxToRem(20) [];
                    padding: 0 .pxToRem(22) [];
                    border-radius: .pxToRem(8) [];
                    border: .pxToRem(1) [] solid @theme_color_first;
                    font-size: .pxToRem(24) [];
                    color: @theme_color_first;
                    line-height: .pxToRem(60) [];
                    text-align: center;
                    box-sizing: border-box;
                    background: #fdf0f0;
                    &.sending {
                        border: .pxToRem(1) [] solid #d9d9d9;
                        background: #f2f2f2;
                        color: rgba(0, 0, 0, 0.15);
                    }
                }
                .submit {
                    height: .pxToRem(80) [];
                    line-height: .pxToRem(80) [];
                    background: rgba(0, 0, 0, 0.15);
                    border-radius: .pxToRem(8) [];
                    font-size: .pxToRem(28) [];
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                    margin-top: .pxToRem(40) [];
                    margin-bottom: .pxToRem(20) [];
                }
                .sure {
                    background-color: @theme_color_first;
                }
                .explain {
                    text-align: center;
                    font-size: .pxToRem(26) [];
                    color: rgba(0, 0, 0, 0.3);
                    line-height: .pxToRem(40) [];
                }
            }
        }
    }
</style>

<template>
    <div class="components mobile">
        <div class="mask"></div>
        <div class="content"
             @click="close">
            <div class="content-bind"
                 @click.stop="">
                <img class="close"
                     :src="require('./icon-close.png')"
                     @click="close" />
                <div class="title">绑定手机号</div>
                <div class="line">
                    <div class="line-title">手机号</div>
                    <input class="input"
                           :style="{width:'100%'}"
                           placeholder="请输入手机号"
                           @blur="inputBlur"
                           v-model="mobile" />
                </div>
                <div class="line code">
                    <div class="line-title">验证码</div>
                    <div class="flex code"
                         @click="focusCode">
                        <input class="input"
                               placeholder="请输入验证码"
                               maxlength="6"
                               ref="password"
                               @blur="inputBlur"
                               v-model="password" />

                    </div>
                    <div class="yzm"
                         :class="{'sending' : isSendCode}"
                         @click.stop="getCode">
                        {{yzm}}
                    </div>
                </div>
                <div class="submit"
                     :class="{'sure':sure}"
                     @click="submit">
                    <div>确定</div>
                </div>
                <div class="explain">
                    <div>验证成功后，将与账号绑定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import store from "@/store"
    import { SMS_SEND, MOBILE_LOGIN, MOBILE_BIND_ACCOUNT } from "@/api"
    let timer = null;
    export default {
        data() {
            return {
                mobile: "",
                password: "",
                sure: false,
                yzm: "获取验证码",
                isSendCode: false,
                time: 0,
            }
        },
        watch: {
            mobile(val) {
                if (this.password.length > 0 && val.length == 11 && val[0] == 1) {
                    this.sure = true;
                }
                else {
                    this.sure = false;
                }
            },
            password(val) {
                if (val.length > 0 && this.mobile.length == 11 && this.mobile[0] == 1) {
                    this.sure = true;
                }
                else {
                    this.sure = false;
                }
            },
            _time(val) {
                if (val > 0) {
                    this.time = val;
                    this.yzm = `${this.time}S后重新获取`;
                    timer = setInterval(() => {
                        this.time--;
                        this.yzm = `${this.time}S后重新获取`;
                        if (this.time == 0) {
                            this.isSendCode = false;
                            clearInterval(timer);
                            this.yzm = "重新获取";
                        }
                    }, 1000);
                }
            }
        },
        props: ["_time", "type"],
        created() {
            this.$util.lockScreen();
        },
        methods: {
            close() {
                this.$util.unlockScreen();
                this.$loginModel.close(this);
            },
            focusCode() {
                this.$refs.password.focus();
            },
            inputBlur() {
                setTimeout(() => {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100)
            },
            getCode() {
                if (this.mobile.length != 11 || this.mobile[0] != 1) {
                    this.$toast("您的手机号输入错误，请重新输入");
                    return;
                }
                if (this.isSendCode) {
                    return;
                }
                this.isSendCode = true;
                this.time = 60;
                timer = setInterval(() => {
                    this.time--;
                    this.yzm = `${this.time}S后重新获取`;
                    if (this.time == 0) {
                        this.isSendCode = false;
                        clearInterval(timer);
                        this.yzm = "重新获取";
                    }
                }, 1000);
                this.$util.post(SMS_SEND, {
                    mobileNo: this.mobile,
                    scene: this.type == 0 ? "2" : "10"
                }).then(res => {
                    if (res.success) {
                        this.$toast("验证码已发送");
                    }
                })
            },
            submit() {
                if (!this.sure) {
                    return;
                }
                this.$util.post(this.type == 0 ? MOBILE_LOGIN : MOBILE_BIND_ACCOUNT, {
                    mobile: this.mobile,
                    mobileNo: this.mobile,
                    verifyCode: this.password,
                    appId: this.$util.isAndroid() ? "10010" : "10011"
                }).then(res => {
                    if (res.success) {
                        if (this.type == 1) {
                            this.$toast("绑定成功");
                            this.$loginModel.callback({ success: true });
                            return;
                        }
                        // 同步权限
                        store.commit("setRoles", res.data.roles);
                        // 同步缓存
                        store.commit("setToken", res.data.accessToken);
                        // 刷新用户信息
                        this.$util.getUserInfo();
                        // 触发回调
                        this.$loginModel.callback({ success: true, data: res.data });
                        // 解锁滚动条
                        this.$util.unlockScreen();
                    }
                    else {
                        this.$loginModel.callback({ success: false });
                    }
                })
            }
        }
    }
</script>
