<style lang="less" scoped>
    @import url("~@/lib/base.less");
    .customer-service {
      .customer-service-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80vw;
        height: 80vh;
        background: white;
        z-index: 100;
        border-radius: .pxToRem(10) [];
        overflow: hidden;
        .custiomer-service-title {
          width: 100%;
          height: .pxToRem(80) [];
          position: relative;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(51, 51, 51, 0.3);
          .full-icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: .pxToRem(80) [];
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: .pxToRem(40) [];
              height: .pxToRem(40) [];
            }
          }
          .close-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: .pxToRem(80) [];
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: .pxToRem(40) [];
              height: .pxToRem(40) [];
            }
          }
        }
        .iframe {
          width: 100%;
          height: calc(100% - .pxToRem(80) []);
          border: none;
        }
        &.full {
          width: 100vw;
          height: 100vh;
          border-radius: 0;
        }
      }
      .mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99;
      }
    }
</style>

<template>
    <div class="components customer-service">
        <div class="customer-service-box"
             :class="{'full': isFull}">
            <div class="custiomer-service-title">
                <div class="full-icon"
                     @click="full">
                    <img :src="require('@/assets/img/full.png')"
                         alt="">
                </div>
                <div class="close-icon"
                     @click="close">
                    <img :src="require('@/assets/img/close2.png')"
                         alt="">
                </div>
            </div>
            <iframe :src="`https://kf.qianzhu8.com/api/mobileweb/home?source=肯德基&channel_id=11178&channel_key=11178h7v9&wechatapp_id=129745&key=37836nsb8&openid=${store.state.user.userInfo.id}&nickName=${(store.state.user.userInfo.nickname + store.state.user.userInfo.id) || ''}&note=${store.state.common.platformId || ''}&avatarUrl=${store.state.user.userInfo.headImgUrl}`"
                    class="iframe"></iframe>
        </div>
        <div class="mask"
             @click="close"></div>
    </div>
</template>
<script>
    import store from '@/store';
    export default {
        data() {
            return {
                isFull: false,
                iframeUrl: '',
                store,
                cssText: '',
            }
        },
        created() {
            this.$util.lockScreen();
        },
        methods: {
            close() {
                this.$util.unlockScreen();
                this.$customerService.close();
            },
            full() {
                this.isFull = !this.isFull;
            }
        }
    }
</script>

