/*
 * @Author: Axs <i@axs.email>
 * @Date: 2021-11-17 16:30:39
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-17 16:32:00
 * @Description:
 * @FilePath: /src/store/modules/kfc.js
 */
const kfc = {
    state: {
        waitUseCardList: [],
    },
    mutations: {
        setWaitUseCardList(state, data) {
            state.waitUseCardList = data;
        },
    },
    getters: {
        waitUseCardList: state => {
            return state.waitUseCardList;
        },
    },
};
export default kfc;
