<style lang="less" scoped>
    @import url("~@/lib/base.less");
    .component-title {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: @titleHeight;
      z-index: 100;
      background: white;
      box-shadow: 0 .pxToRem(3) [] .pxToRem(20) [] rgba(0, 0, 0, 0.2);
      .titile-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: @titleHeight;
        background: white;
      }
      .back-icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        padding: 0 .pxToRem(24) [];
        width: .pxToRem(22) [];
        height: 100%;
        z-index: 5;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: .pxToRem(22) [];
          height: .pxToRem(38) [];
        }
      }
      .title-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: @titleHeight;
        font-size: .pxToRem(36) [];
        font-weight: bold;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
        box-sizing: border-box;
        padding: 0 .pxToRem(130) [];
        z-index: 1;
      }
      .title-right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        min-width: .pxToRem(34) [];
        padding: 0 .pxToRem(20) [];
        z-index: 5;
        &.mg-title-right {
          .mg-share {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: .pxToRem(34) [];
            height: .pxToRem(34) [];
            padding: 0 .pxToRem(20) [];
          }
          .mg-text {
            height: @titleHeight;
            line-height: @titleHeight;
            box-sizing: border-box;
            padding-left: .pxToRem(54) [];
            font-size: .pxToRem(24) [];
          }
        }
      }
      .mg-system {
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        padding-top: 10px;
        background: black;
      }
    }
</style>

<template>
    <div class="components component-title"
         :style="{height:'calc(' + $store.state.common.title.systemHeight + ' + ' + $store.state.common.title.titleHeight+ ')'}">
        <div class="mg-system"
             :style="{height:($store.state.common.currentScene == QZ_CURRENT_SCENE.APP_MG ? $store.state.common.title.systemHeight : 0)}">
        </div>
        <div class="titile-box"
             ref="titleBox">
            <div class="back-icon"
                 @click="back">
                <img :src="require('@/assets/img/back.png')">
            </div>
            <div class="title-content"
                 ref="titleName">{{titleName}}</div>
            <!-- <div class="title-right mg-title-right"
                 @click="mgShare"
                 v-if="$store.state.common.currentScene === QZ_CURRENT_SCENE.APP_MG">
                <div class="mg-text">分享</div>
                <img class="mg-share"
                     :src="require('@/assets/img/mg-share.png')">
            </div> -->
            <slot name="title"></slot>
            <slot name="title-right"></slot>
        </div>
    </div>
</template>
<script>
    import { QZ_CURRENT_SCENE, MAIN_TABBER_LIST } from 'constant';
    import { PAGE_TABBAR_NAME, PAGE_RESTAURANT_SELECT, PAGE_ORDER_LIST, PAGE_EAT_TYPE } from 'page';
    export default {
        data() {
            return {
                QZ_CURRENT_SCENE,
            }
        },
        watch: {
        },
        computed: {
            titleName() {
                let name = '';
                if (this.$route.query.titleName && this.$route.query.titleName.length > 0) {
                    name = this.$route.query.titleName;
                }
                else if (this.$store.state.common.title.titleName && this.$store.state.common.title.titleName.length > 0) {
                    name = this.$store.state.common.title.titleName;
                    this.$store.commit('clearTitleName');
                }
                else if (this.$route.meta && this.$route.meta.zhCnName && this.$route.meta.zhCnName.length > 0) {
                    name = this.$route.meta.zhCnName;
                }
                return name;
            },
        },
        created() {
            let shareObj = {
                canShare: 1,
                appShareType: 78,
                webShareType: 55,
                showType: 2,
                shareUrl: 'http://www.baidu.com',
                shareTitle: 'this is title',
                description: 'this is description',
                pic: 'http://www.baidu.com',
            }
            location.href = 'mg://web?action=toInit&data=' + encodeURIComponent(JSON.stringify(shareObj));
            this.$nextTick(() => {
                this.$store.commit('setTitleHeight', this.$refs.titleBox.clientHeight + 'px');
                let style = document.createElement("style");
                let styleStr = `
                    .page{
                        padding-top:calc(${this.$store.state.common.title.titleHeight} + ${this.$store.state.common.title.systemHeight})
                    }
                `;
                if (style.styleSheet) {
                    style.styleSheet.cssText = styleStr;
                }
                else {
                    style.innerHTML = styleStr;
                }
                document.body.appendChild(style);
            })
        },
        methods: {
            back() {
                let rootList = [];
                for (let i = 0; i < MAIN_TABBER_LIST.length; i++) {
                    const item = MAIN_TABBER_LIST[i];
                    rootList.push(item.path);
                }
                if (this.$route.path.indexOf(PAGE_TABBAR_NAME) > -1) {
                    if (QZ_CURRENT_SCENE.APP_MG == this.$store.state.common.currentScene) {
                        try {
                            window.zutuanApp.finish();
                        } catch (error) {
                            window.mgApp.finish();
                        }
                    }
                    else {
                        // 已经在根目录 不能返回
                        console.log(`已经在根目录，不能返回`);
                    }
                }
                else if (rootList.indexOf(this.$route.path) > -1 || this.$route.path.indexOf(PAGE_TABBAR_NAME) > -1) {
                    if (QZ_CURRENT_SCENE.APP_MG == this.$store.state.common.currentScene) {
                        try {
                            window.zutuanApp.finish();
                        } catch (error) {
                            window.mgApp.finish();
                        }
                    }
                    else {
                        // 已经在根目录 不能返回
                        console.log(`已经在根目录，不能返回`);
                    }
                }
                else {
                    if (QZ_CURRENT_SCENE.APP_MG == this.$store.state.common.currentScene) {
                        try {
                            window.zutuanApp.back();
                        } catch (error) {
                            window.mgApp.back();
                        }
                    }
                    else {
                        this.$util.pageBack();
                    }

                }
            },
            mgShare() {
                try {
                    window.zutuanApp.share()
                } catch (error) {
                    window.mgApp.share();
                }

            }
        },
    }
</script>
