/**
 * 获取收获地址列表
 */
export const GET_ADDRESS_LIST = '/api/user/address/pagedQuery';
/**
 * 获取该地址详情
 */
export const GET_ADDRESS_ITEM = '/api/user/address';
/**
 * 修改收获地址
 */
export const EDIT_ADDRESS_ITEM = '/api/user/address/update';
/**
 * 新增收获地址
 */
export const ADD_ADDRESS_ITEM = '/api/user/address/insert';
/**
 * 删除收货地址
 */
export const DELETE_ADDRESS_ITEM = '/api/user/address/delete'