import confirm from './index.vue';
import Vue from 'vue';

let el = null;
let successFun = null;
let errorFun = null;

const _confirm = {
    // confirm 打开
    open({
        title = '系统提示',
        message = '',
        confirmText = '确定',
        cancelText = '取消',
        switchBtn = false,
        success = null,
        error = null,
        hideCancelBtn = false,
        isShieldClose = true,
    } = {}) {
        if (el) {
            return;
        }
        let model = Vue.extend(confirm);
        let cfVm = new model();
        cfVm.title = title;
        cfVm.message = message;
        cfVm.confirmText = confirmText;
        cfVm.cancelText = cancelText;
        cfVm.switchBtn = switchBtn;
        cfVm.hideCancelBtn = hideCancelBtn;
        cfVm.isShieldClose = isShieldClose;
        successFun = success;
        errorFun = error;
        el = cfVm.$mount().$el;
        // 插入dom
        document.body.appendChild(el);
    },
    success(data = {}) {
        if (successFun) {
            successFun(data);
        }
    },
    error(data) {
        if (errorFun) {
            errorFun(data);
        }
    },
    close() {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
    },
};

export default _confirm;
