<template>
    <div class="components mobile">
        <div class='mask'></div>
        <div class='content'
             @click="close">
            <div class='content-bind'
                 @click.stop="">
                <img class='close'
                     :src="require('./icon-close.png')"
                     @click="close" />
                <div class='title'>请选择支付方式</div>
                <div class="pay-line"
                     v-for="(item ,index) in payList"
                     :key="item.type"
                     @click="clickPayType(index)">
                    <div class="title">
                        <img class="icon"
                             :src="item.icon">
                        <div class="name">{{item.name}}
                            <span class="tip" v-if="1 == item.type && new Date().getTime() < 1640923200000">首单随机立减</span>
                        </div>
                    </div>
                    <div class="value">
                        <img class="icon"
                             :src="selectIndex == index?require('./icon-check-on.png') :require('./icon-check-not.png')">
                    </div>
                </div>
                <div class="submit"
                     @click="submit()"
                     :class="{'sure' : selectIndex > -1}">
                    <div>确定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import payType from './index';
    import { QZ_PAY_TYPE } from 'constant';
    export default {
        data() {
            return {
                payList: [{
                    name: '支付宝支付',
                    icon: require('./icon-alipay.png'),
                    type: QZ_PAY_TYPE.ALIPAY_H5
                }, {
                    name: '微信支付',
                    icon: require('./icon-wechat.png'),
                    selectColor: '#49c483',
                    type: QZ_PAY_TYPE.WXPAY_H5
                }],
                selectIndex: 0,
            }
        },
        created() {
            this.$util.lockScreen();
        },
        methods: {
            close() {
                this.$util.unlockScreen();
                payType.close(this);
            },
            clickPayType(index) {
                this.selectIndex = index;
            },
            submit() {
                if (this.selectIndex > -1) {
                    payType.callback({
                        success: true,
                        data: {
                            payName: this.payList[this.selectIndex].name,
                            payType: this.payList[this.selectIndex].type
                        }
                    })
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('../../lib/base');
    .mobile {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
        .content {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .content-bind {
                .base-center;
                position: relative;
                width: .pxToRem(650) [];
                height: .pxToRem(560) [];
                background: rgba(255, 255, 255, 1);
                border-radius: .pxToRem(40) [];
                padding: .pxToRem(40) [] .pxToRem(50) [];
                box-sizing: border-box;
                .pay-line {
                    position: relative;
                    height: .pxToRem(120) [];
                    line-height: .pxToRem(120) [];
                    font-size: .pxToRem(28) [];
                    border-radius: .pxToRem(60) [];
                    border: .pxToRem(1) [] solid #e5e5e5;
                    box-sizing: border-box;
                    margin-top: .pxToRem(20) [];
                    .title,
                    .value {
                        position: unset;
                    }
                    .title {
                        float: left;
                        .icon {
                            .base-center-y;
                            left: .pxToRem(26) [];
                            .sq(64);
                        }
                        .name {
                            .base-center-y;
                            left: .pxToRem(110) [];
                            font-size: .pxToRem(28) [];
                            color: rgba(0, 0, 0, 0.9);
                        }
                        .tip {
                            font-size: .pxToRem(22) [];
                            color: @theme_color_first;
                        }
                    }
                    .value {
                        float: right;
                        .icon {
                            .base-center-y;
                            right: .pxToRem(30) [];
                            .sq(44);
                        }
                    }
                }
                .close {
                    position: absolute;
                    top: .pxToRem(36) [];
                    right: .pxToRem(30) [];
                    width: .pxToRem(30) [];
                    height: .pxToRem(30) [];
                }
                > .title {
                    margin-bottom: .pxToRem(34) [];
                    font-size: .pxToRem(34) [];
                    height: .pxToRem(36) [];
                    line-height: .pxToRem(36) [];
                    text-align: center;
                    font-weight: 600;
                    color: #000000;
                }
                .phone {
                    font-size: .pxToRem(24) [];
                    color: rgba(81, 81, 81, 1);
                    line-height: .pxToRem(33) [];
                    border-bottom: 1px solid #f0f0f0;
                    margin-top: .pxToRem(40) [];
                }
                .submit {
                    height: .pxToRem(80) [];
                    line-height: .pxToRem(80) [];
                    background: rgba(189, 190, 192, 1);
                    border-radius: .pxToRem(8) [];
                    font-size: .pxToRem(28) [];
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                    margin-top: .pxToRem(40) [];
                }
                .sure {
                    color: rgba(255, 255, 255, 1);
                    background-color: @theme_color_first;
                }
            }
        }
    }
</style>