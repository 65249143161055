import loginModel from './index.vue';
import Vue from 'vue';

let el = null;
let time = 0;
let timer = null;
let successFun = null;

const _loginModel = {
    // loginModel 打开
    open({ success = null, type = 0 } = {}) {
        if (el) {
            return;
        }
        successFun = success;
        let model = Vue.extend(loginModel);
        let loginVM = new model()
        loginVM.type = type;
        loginVM._time = time;
        el = loginVM.$mount().$el;
        // 插入dom
        document.body.appendChild(el);
        // 清除时间
        if (timer) {
            clearInterval(timer);
        }
    },
    close(_this = null) {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
        if (_this) {
            // 同步时间设置
            time = _this.time;
            if (_this.time < 1) {
                return;
            }
            time--;
            timer = setInterval(() => {
                time--;
                if (time < 1) {
                    clearInterval(timer);
                }
            }, 1000);
        }
    },
    // 回调触发方法
    callback(options) {
        if (options.success) {
            if (successFun) {
                successFun();
            }
            else {
                this.close();
            }
        }
    }
}

export default _loginModel;