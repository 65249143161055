import pickerModel from './index.vue';
import Vue from 'vue';

let el = null;
let closeFun = null;
let cancelFun = null;
let confirmFun = null;

const _pickerModel = {
    // pickerModel 打开
    open({ list = [], keyName = '', close = null, cancel = null, confirm = null } = {}) {
        if (el) {
            return;
        }
        closeFun = close;
        cancelFun = cancel;
        confirmFun = confirm;
        let model = Vue.extend(pickerModel);
        let pickerVM = new model()
        pickerVM.list = list;
        pickerVM.keyName = keyName;
        pickerVM.close = close;
        pickerVM.cancel = cancel;
        pickerVM.confirm = confirm;
        el = pickerVM.$mount().$el;
        document.body.appendChild(el);
    },
    close() {
        if (!el) {
            return;
        }
        // 移除dom
        document.body.removeChild(el);
        el = null;
    },
}

export default _pickerModel;