/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-12-28 15:04:42
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-05-25 15:25:07
 * @Description:
 * @FilePath: /src/lib/page.js
 */
const BASE_URL = process.env.VUE_APP_BASE_URL;
// 根目录
export const PAGE_ROOT = BASE_URL + '/';
// tabbar 名称前缀
export const PAGE_TABBAR_NAME = BASE_URL + '/main';
// 首页
export const PAGE_RESTAURANT_SELECT = BASE_URL + '/restaurant/select';
// 设置城市
export const PAGE_SET_CITY = BASE_URL + '/set/city';
// 切换餐厅列表
export const PAGE_RESTAURANT_LIST = BASE_URL + '/restaurant/list';
// 餐厅菜单
export const PAGE_STOP_MENU = BASE_URL + '/restaurant/menu';
// 餐品详情
export const PAGE_PRODUCT_DETAIL = BASE_URL + '/restaurant/detail';
// 创建订单
export const PAGE_ORDER_CREATE = BASE_URL + '/order/create';
// 阿奇索确定页面
export const PAGE_ORDER_CREATE_BY_AGISO = BASE_URL + '/order/create4agiso';
// 订单列表
export const PAGE_ORDER_LIST = BASE_URL + '/order/list';
// 订单详情
export const PAGE_ORDER_INFO = BASE_URL + '/order/info';
// 订单支付页面
export const PAGE_ORDER_PAY = BASE_URL + '/order/pay';
// 客服
export const PAGE_CUSTOMER_SERVICE = BASE_URL + '/customer-service';
// 旧的首页
export const PAGE_OLD_INDEX = BASE_URL + '/old/index';
// 旧的订单列表
export const PAGE_OLD_ORDER_LIST = BASE_URL + '/old/order/list';
// 收货地址
export const PAGE_ADDRESS_LIST = BASE_URL + '/address/list';
// 添加地址
export const PAGE_ADDRESS_DETAIL = BASE_URL + '/address/detail';
// 地址搜索
export const PAGE_ADDRESS_SEARCH = BASE_URL + '/address/search';
// 就餐方式选择
export const PAGE_EAT_TYPE = BASE_URL + '/eat-type';
// 就餐方式选择
export const PAGE_NOT_TAB_EAT_TYPE = BASE_URL + '/not-tab/eat-type';
// 其他域名公共的微信支付
export const PAGE_WECHAT_PAY = BASE_URL + '/wechat/pay';
// 微信分享中转页面
export const PAGE_WX_SHARE = BASE_URL + '/wx/share';
// 我的卡包
export const PAGE_KFC_CARD = BASE_URL + '/card';
// 门店中的卡包
export const PAGE_KFC_RESTAURANT_CARD = BASE_URL + '/restaurant/card';
// 肯德基我的钱包
export const PAGE_WALLET = BASE_URL + '/wallet';
// 快递订单详情
export const PAGE_EXPRESS = BASE_URL + '/express';
