/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-07-17 14:51:21
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-11-11 19:30:37
 * @Description:
 * @FilePath: /src/api/modules/kfc.js
 */
/**
 * 获取附近的餐厅
 */
export const GET_LARTELY_STOP = '/api/kfcStore/pagedQuery';
/**
 * 收藏的餐厅
 */
export const GET_LOVE_STOP = '/api/kfcFavoriteStore/pagedQuery';
/**
 * 获取餐厅数量
 */
export const GET_STOP_NUM = '/api/kfcStore/union';
/**
 * 收藏餐厅
 */
export const SET_LOVE_STOP = '/api/kfcFavoriteStore/favorite';
/**
 * 获取餐厅信息
 */
export const GET_STOP_MENU = '/api/kfcMenu/listByStoreCode';
/**
 * 获取外卖餐厅信息
 */
export const GET_OUT_BY_STOP_MENU = '/api/kfcMenu/listTakeoutMenus';
/**
 * 获取餐厅信息
 */
export const GET_STOP_INFO = '/api/kfcStore/getByStoreCode';
/**
 * 获取餐厅是否收藏
 */
export const GET_STOP_FAVORITE = '/api/kfcFavoriteStore/checkCollected';
/**
 * 获取肯德基餐厅通知信息
 */
export const GET_KFC_STORE_NOTICE = '/api/systemNotice/listNoticeByType/1';
/**
 * 验证肯德基外卖订单
 */
export const VALID_TAKEOUT_ORDER = '/api/kfcOrder/validTakeoutOrder';
/**
 * 获取购物车列表
 */
export const GET_CART_LIST = '/api/kfcShoppingCart/getCartByStoreCode';
/**
 * 购物车添加商品
 */
export const ADD_PRODUCT_FOR_CART = '/api/kfcShoppingCart/addProduct';
/**
 * 购物车减少商品
 */
export const REDUCE_PRODUCT_FOR_CART = '/api/kfcShoppingCart/reduceProduct';
/**
 * 购物车清空
 */
export const CLEAR_CART_LIST = '/api/kfcShoppingCart/clearCartByStoreCode';
/**
 * 使用优惠券
 */
export const USE_KFC_CARD = '/api/kfcShoppingCart/useCard';
/**
 * 获取可用优惠券
 */
export const GET_USER_AVAILABLE_CARD_LIST = '/api/kfcCards/listAvailable';
/**
 * 兑换优惠券
 */
export const EXCHANGE_CARD = '/api/kfcCodes/exchange';
/**
 * 取消待支付订单
 */
export const CANCEL_NOT_PAID_ORDER = '/api/kfcOrder/cancelNotPaidOrder';
/**
 * 获取可用优惠券数量
 */
export const GET_USER_AVAILABLE_CARD_COUNT = '/api/kfcCodes/getAvailableCount';
/**
 * 获取优惠券可选商品
 */
export const GET_KFC_CARD_PRODUCT = '/api/kfcShoppingCart/listAvailableKfcCardProduct';
/**
 * 使用优惠券
 */
export const USE_CARD_TO_PRODUCT = '/api/kfcShoppingCart/useCard';
/**
 * 获取账户可用代金券列表
 */
export const GET_KFC_VOUCHER = '/api/kfcVouchers/listAvailable';
/**
 * 获取购物车可用代金券
 */
export const GET_KFC_VOUCHER_4_CART = '/api/kfcVouchers/listAvailable4Cart';
/**
 * 使用代金券
 */
export const USE_KFC_VOUCHER = '/api/kfcShoppingCart/useVoucher';
/**
 * 获取该地址可配送门店
 */
export const GET_USER_ADDRESS_STORE = '/api/kfcStore/peiSong/listDeliveryStoreByAddressId';
/**
 * 计算配送费
 */
export const GET_DELIVERY_FEE = '/api/kfcStore/peiSong/calculateDeliveryFee';
/**
 * 获取订单详情
 */
export const GET_PRODUCT_BY_ID = '/api/kfcProduct/getDetailById';