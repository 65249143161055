/**
 * 修改 Toast 默认参数
 */
import { Toast } from 'mint-ui';
// 引入时间常量
import { TOAST_STOP_TIME } from '@/lib/constant';

const toast = (options) => {
    if ('string' === typeof options && options.length > 0) {
        return Toast({
            message: options,
            duration: TOAST_STOP_TIME
        });
    }
    else {
        if (!options.duration) {
            options.duration = TOAST_STOP_TIME;
        }
        if(options.message.length > 0){
            return Toast(options);
        }
    }
}
export default toast;