import axios from 'axios';
import store from '@/store/';
import loginModel from '@/components/loginModel';
import Toast from '@/lib/mint/toast';
import { QZ_CURRENT_SCENE, QZ_HEADER_APP_ID } from 'constant';
import util from 'util';
import { PAGE_ROOT } from 'page';

let headerAppIdIsInit = false;
let headerAppId = '';

const ajax = axios.create({
    timeout: 30000,
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
        appId: 10008,
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

function userLogin() {
    if (
        //  !store.state.common.platformId &&
        store.state.common.currentScene >= QZ_CURRENT_SCENE.WX_BROWSER &&
        store.state.common.currentScene < QZ_CURRENT_SCENE.WX_BROWSER + QZ_CURRENT_SCENE.STEP
    ) {
        util.wxMpLogin();
    } else {
        loginModel.open();
    }
}
function requestPreprocessing(res) {
    if (res.status === 200) {
        if (typeof res.data == 'string') {
            let _resData = Object.assign({}, res);
            res.data = {
                data: _resData.data,
            };
        }
        if (res.data.code == 7001) {
            loginModel.open({ type: 1 });
            return { success: false };
        } else if (res.data.code == 7010) {
            util.wxMpLogin();
            return {
                code: res.data.code,
                success: false,
            };
        } else if (res.data.code == 8001) {
            return {
                code: res.data.code,
                success: false,
            };
        } else if (res.data.code == 20001) {
            util.pageJump(PAGE_ROOT);
            res.data.success = false;
            return res.data;
        } else if (res.data.code != 10000 && res.data.code != 200) {
            if (res.data.message) {
                Toast(res.data.message);
            }
            res.data.success = false;
            return res.data;
        }
        return res.data;
    } else {
        return {
            code: 5000,
            success: false,
            message: '网络打了一个瞌睡～',
        };
    }
}
function requestError(error) {
    if (error.response && error.response.status === 401) {
        store.commit('clearToken');
        if (store.state.common.currentScene === 0) {
            util.setCurrentScene().then(res => {
                userLogin();
            });
        } else {
            userLogin();
        }
    }
    return { success: false, message: error };
}

function getRedirectUrl() {
    let href = window.location.href;
    href = `${href}${href.split('?').length > 1 ? '&pro=' : '?pro='}${store.state.common.env}`;
    return href;
}

function getHeaders() {
    let result = {
        wxOpenId: store.state.user.wxOpenId || '',
        platformId: store.state.common.platformId || '',
        subPlatformId: store.state.common.subPlatformId || '',
        Authorization: store.state.user.token || '',
        platformUserOpenId: store.state.user.platformUserOpenId || '',
        platformAppId: store.state.user.platformAppId || '',
        merchId: store.state.common.merchId || '',
        promoterId: store.state.common.promoterId || '',
        redirectUrl: getRedirectUrl(),
    };
    if (!headerAppIdIsInit) {
        headerAppId = localStorage.getItem(QZ_HEADER_APP_ID);
    }
    if(headerAppId){
        result.appId = headerAppId;
    }
    return result;
}

export function GET(...args) {
    let type = 0;
    let url = '';
    let data = {};
    let config = {
        isJsonText: false,
    };
    if (typeof args[0] === 'string') {
        type = 0;
        url = args[0];
        data = args[1];
        config = args[2] || config;
    } else {
        type = 1;
        url = args[0].url;
        data = args[0].data;
        config = args[0].config || config;
    }
    return ajax({
        method: 'get',
        url,
        params: data,
        headers: getHeaders(),
    })
        .then(res => {
            return requestPreprocessing(res);
        })
        .catch(error => {
            return requestError(error);
        });
}

export function POST(...args) {
    let type = 0;
    let url = '';
    let data = {};
    let config = {
        isJsonText: false,
    };
    if (typeof args[0] === 'string') {
        type = 0;
        url = args[0];
        data = args[1];
        config = args[2];
    } else {
        type = 1;
        url = args[0].url;
        data = args[0].data;
        config = args[0].config;
    }

    return ajax({
        method: 'post',
        url,
        data,
        headers: getHeaders(),
    })
        .then(res => {
            return requestPreprocessing(res);
        })
        .catch(error => {
            return requestError(error);
        });
}
