/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-11-25 17:29:58
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-05-24 21:10:27
 * @Description:
 * @FilePath: /src/lib/constant.js
 */
import { PAGE_EAT_TYPE, PAGE_ORDER_LIST, PAGE_KFC_CARD } from 'page';
// 项目简称
export const QZ_ABBREVIATION = '肯德基';
// 千猪缓存名称
export const QZ_TOKEN = 'QZ_TOKEN';
// 高德地图Key
export const GAUD_KEY = 'eca14d46f9d1b03760f6f63255e1c193';
// 千猪平台id
export const QZ_PLATFORM_ID = 'QZ_PLATFORM_ID';
// 千猪子平台id
export const QZ_SUB_PLATFORM_ID = 'QZ_SUB_PLATFORM_ID';
// 当前浏览器定位
export const LOCATION_CURRENT = 'QZ_LOCATION_CURRENT';
// 用户设定的定位
export const LOCATION_SET = 'QZ_LOCATION_SET';
// Toast 停留时长(ms)
export const TOAST_STOP_TIME = 1500;
// 获取授权未知时长
export const GET_LOCATION_TIME = 5000;
// 千猪页面传输名称
export const QZ_ROUTER_PARAMS = 'QZ_ROUTER_PARAMS';
// 千猪历史访问缓存列表
export const QZ_LOCATION_HISTORY_LIST = 'QZ_LOCATION_HISTORY_LIST';
// 肯德基缓存名称
export const QZ_KFC_CART_NAME = 'QZ_KFC_CART_LIST';
// 肯德基缓存商品详情
export const QZ_KFC_PRODUCT_INFO = 'QZ_KFC_PRODUCT_INFO';
// 收获地址当前搜索缓存名称
export const QZ_ADDRESS_SEARCH_NAME = 'QZ_ADDRESS_SEARCH_NAME';
// 订单切换收货地址存储id
export const QZ_ADDRESS_SAVE_ID = 'QZ_ADDRESS_SAVE_ID';
// 选择支付页面 存储当前支付的订单信息
export const QZ_PAY_ORDER_NO = 'QZ_PAY_ORDER_NO';
// 肯德基商品历史搜索
export const QZ_KFC_PRODUCT_SEARCH_LIST = 'QZ_KFC_PRODUCT_SEARCH_LIST';
// 当前环境值来源类型
export const QZ_SCENE_TYPE = 'QZ_SCENE_TYPE';
// 当前环境场景值
export const QZ_CURRENT_SCENE_VALUE = 'QZ_CURRENT_SCENE';
// 渠道用户用户Id
export const QZ_PLATFORM_USER_OPEN_ID = 'QZ_PLATFORM_USER_OPEN_ID';
// 渠道用户openId
export const QZ_PLATFORM_APP_ID = 'QZ_PLATFORM_APP_ID';
// 微信openId
export const QZ_WX_OPEN_ID = 'QZ_WX_OPEN_ID';
// 商户id
export const QZ_MERCH_ID = 'QZ_MERCH_ID';
// 推广员id
export const QZ_PROMOTER_ID = 'QZ_PROMOTER_ID';
// QZ_HEADER_APP_ID
export const QZ_HEADER_APP_ID = 'QZ_HEADER_APP_ID';
// 综合服务校验域名正则
export const QZ_INTEGRATED_SERVICES_DOMAIN = /wx.*?\.wx\.qianzhu8\.cn/;
// 默认配送费
export const QZ_KFC_DEFAULT_DELIVERY_FEE = 9;
// 客服配置
export const QZ_KF_CONFIG = {
    SOURCE: '肯德基',
    CHANNEL_ID: '11178',
    CHANNEL_KEY: '11178h7v9',
    WECHATAPP_ID: '129745',
    KEY: '37836nsb8',
};

// main tabber list
export const MAIN_TABBER_LIST = [
    {
        icon: require('@/assets/icons/choice-restaurant.png'),
        selectIcon: require('@/assets/icons/choice-restaurant-on.png'),
        name: '首页点餐',
        path: PAGE_EAT_TYPE,
    },
    {
        icon: require('@/assets/icons/order-list.png'),
        selectIcon: require('@/assets/icons/order-list-on.png'),
        name: '我的订单',
        path: PAGE_ORDER_LIST,
    },
    {
        icon: require('@/assets/icons/my-card.png'),
        selectIcon: require('@/assets/icons/my-card-on.png'),
        name: '我的卡包',
        path: PAGE_KFC_CARD,
    },
];
// 当前环境参数代表
export const QZ_CURRENT_SCENE = {
    APP: 1000, // 普通app && 其他浏览器
    APP_MG: 1001, // 美逛app
    APP_BLUE_CS: 1002, // 蓝晶社app
    WX_MIN: 2000, // 微信小程序
    WX_BROWSER: 3000, // 微信浏览器
    TT_MIN: 4000, // 头条小程序
    ALIPAY_BROWSER: 5000, // 支付宝浏览器
    ALIPAY_MIN: 6000, // 支付宝小程序
    BD_MIN: 7000, // 百度小程序
    STEP: 1000, // 俩俩之间间隔
};
// 支付方式
export const QZ_PAY_TYPE = {
    ALIPAY_H5: 1, // 支付宝H5支付
    ALIPAY_MIN: 2, // 支付宝小程序支付
    WXPAY_H5: 3, // 微信H5支付
    WXPAY_MIN: 4, // 微信小程序支付
    WXPAY_BROWSER: 5, // 微信浏览器支付
    TTPAY_MIN: 6, // 头条小程序支付
    BDPAY_MIN: 7, //  百度小程序支付
    OTHERPAY: 99,
};
