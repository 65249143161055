import Vue from 'vue';
import App from './App.vue';
import VueLazyload from 'vue-lazyload';
import './registerServiceWorker';
import router from './router';
import store from '@/store';
import util from '@/lib/util';
import '@/lib/flexibel/flexible.debug.js';
import loginModel from '@/components/loginModel';
import customerService from '@/components/customerService';
import picker from '@/components/picker';
import confirm from '@/components/confirm';
import iframe from '@/components/iframe';
// 路由 &&  添加标签
import '@/permission';
// 图片懒加载
Vue.use(VueLazyload);

// 设置代码运行环境
store.commit('setEnv', process.env.VUE_APP_MY_NODE);

// 将 Toast 注入全局
import Toast from '@/lib/mint/toast';
import 'mint-ui/lib/style.css';
Toast.install = function(Vue) {
    Vue.prototype.$toast = Toast;
};
Vue.use(Toast);

import { MessageBox, Indicator } from 'mint-ui';
MessageBox.install = function(Vue) {
    Vue.prototype.$message = MessageBox;
};
Vue.use(MessageBox);

Indicator.install = function(Vue) {
    Vue.prototype.$loading = Indicator;
};
Vue.use(Indicator);

// 将文本复制到粘贴板
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// 将 util 注入全局
Vue.prototype.$util = util;

// 将 loginModel 注入全局
Vue.prototype.$loginModel = loginModel;

// 将 customerService 注入全局
Vue.prototype.$customerService = customerService;

// 将 picker 注入全局
Vue.prototype.$picker = picker;

// 将 confirm 注入全局
Vue.prototype.$confirm = confirm;

// 讲iframe 注入全局
Vue.prototype.$iframe = iframe;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
